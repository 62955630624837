import { getById, isTabFocused, querySelector } from './util/dom';

let nameEl: HTMLInputElement, emailEl: HTMLInputElement, messageEl: HTMLTextAreaElement, allInputs: FormInputElement[];

let buttonEl: HTMLButtonElement;
let formEl: HTMLFormElement;
let lastButtonClick = {x: 0, y: 0};

export function registerContactFormHandler() {
  nameEl = getFormEl('name') as HTMLInputElement;
  emailEl = getFormEl('email') as HTMLInputElement;
  messageEl = getFormEl('message') as HTMLTextAreaElement;
  allInputs = [nameEl, emailEl, messageEl];

  buttonEl = getById('submit-button') as HTMLButtonElement;
  formEl = getById('contact-form') as HTMLFormElement;

  // The button is just a div, so we have to fullfill the ARIA reqiurements of a button manually.
  // See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
  buttonEl.addEventListener('click', e => {
    lastButtonClick.x = e.clientX;
    lastButtonClick.y = e.clientY;
    formEl.requestSubmit();
  })
  buttonEl.addEventListener('keydown', e => {
    if (e.code === 'Space' || e.code === 'Enter') {
      clearLastButtonClick();
      formEl.requestSubmit();
    }
  });

  formEl.addEventListener('submit', e => {
    e.preventDefault();
    if (isFormDisabled()) return;

    const valid = validateContactForm();
    startButtonClickAnimation(valid);
    if (!valid) return;

    showFormError('');
    setFormDisabled(true);
    function onSuccess() {
      setFormDisabled(false);
      formEl.style.height = formEl.offsetHeight + 'px';
      formEl.innerHTML = '<div id="sent-message">Your email has been sent.</div>'
    }

    function onError() {
      showFormError('Something went wrong. Please try again.');
      setFormDisabled(false);
    }
    postForm(formEl, onSuccess, onError);
  });

  for (const input of allInputs) {
    for (const eventName of ['focus', 'change']) {
      input.addEventListener(eventName, (e) => (e.target as FormInputElement).classList.remove('invalid'));
    }
  }
}

function isFormDisabled(): boolean {
  return buttonEl.ariaDisabled === 'true';
}

function setFormDisabled(disabled: boolean) {
  for (const el of allInputs) {
    el.disabled = true;
  }
  buttonEl.ariaDisabled = disabled ? 'true' : 'false';
}

type FormInputElement = HTMLInputElement|HTMLTextAreaElement;

// Via https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validateContactForm() {
  const invalidInputs = allInputs.filter(isEmptyInput);
  let errorMessage = '';
  if (invalidInputs.length) {
    errorMessage = 'all fields are required';
  }
  if (!EMAIL_REGEX.test(emailEl.value)) {
    if (!errorMessage) {
      errorMessage = 'that email address looks fake';
    }
    if (!invalidInputs.includes(emailEl)) {
      invalidInputs.push(emailEl);
    }
  }
  if (errorMessage) {
    showFormError(errorMessage, invalidInputs);
    return false;
  }
  return true;
}

function showFormError(message: string, fields: FormInputElement[] = []) {
  getById('validation-error').textContent = message;
  for (const field of fields) {
    field.classList.add('invalid');
  }
}

function getFormEl(name: string): FormInputElement {
  return getById('contact-form').querySelector('[name="' + name + '"]') as FormInputElement;
}

function isEmptyInput(el: FormInputElement) {
  return !el.value.trim();
}


// Adapted from https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
function postForm(formEl: HTMLFormElement, onSuccess: (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => void, onError: (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => void) {
  var xhr = new XMLHttpRequest();
  xhr.addEventListener('load', onSuccess);
  xhr.addEventListener('error', onError);
  xhr.open('POST', formEl.action);
  xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
  xhr.send(urlEncodeFormInputs(formEl));
}

function urlEncodeFormInputs(formEl: HTMLElement): string {
  var formData: string[] = [];
  var inputs = formEl.querySelectorAll('input, textarea') as NodeListOf<FormInputElement>;
  for (var i = 0; i < inputs.length; i++) {
    var el = inputs[i];
    var encoded = encodeURIComponent(el.name) + '=' +
        encodeURIComponent(el.value).replace( /%20/g, '+' );
    formData.push(encoded);
  }
  return formData.join('&');
}

const FORM_DISC_LENGTH = 300; // pixels
const BUTTON_DISC_LENGTH = 300; // pixels

function startButtonClickAnimation(valid: boolean) {
  const formRect = formEl.getBoundingClientRect();
  const buttonRect = buttonEl.getBoundingClientRect();

  // If they is no click (like pressing enter on a text field), use
  // the center of the button.
  if (lastButtonClick.x === 0 || lastButtonClick.y === 0) {
    lastButtonClick.x = (buttonRect.left + buttonRect.right) / 2;
    lastButtonClick.y = (buttonRect.top + buttonRect.bottom) / 2;
  }

  const formDisc = document.querySelector('#contact-form > .disc') as HTMLDivElement;
  const buttonDisc = document.querySelector('#submit-button > .disc') as HTMLDivElement;
  const discEls = [formDisc, buttonDisc];
  for (const disc of discEls) {
    disc.style.transition = '';
    disc.style.transform = 'scale3d(0,0,1)';
  }
  formDisc.style.left = (lastButtonClick.x - formRect.left - (FORM_DISC_LENGTH / 2)) + 'px';
  formDisc.style.top = (lastButtonClick.y - formRect.top - (FORM_DISC_LENGTH / 2)) + 'px';
  formDisc.style.opacity = '0.3';
  buttonDisc.style.left = (lastButtonClick.x - buttonRect.left - (BUTTON_DISC_LENGTH / 2)) + 'px';
  buttonDisc.style.top = (lastButtonClick.y - buttonRect.top - (BUTTON_DISC_LENGTH / 2)) + 'px';
  buttonDisc.style.opacity = '1';

  // force a redraw
  buttonDisc.offsetHeight;

  formDisc.style.transition = 'all 0.8s 0.1s ease-out';
  buttonDisc.style.transition = 'all 0.8s ease-out';
  for (const disc of discEls) {
    disc.style.transform = 'scale3d(1,1,1)';
    disc.style.opacity = '0';
  }

  clearLastButtonClick();
}

function clearLastButtonClick() {
  lastButtonClick.x = 0;
  lastButtonClick.y = 0;
}
import {Engine} from './movement/engine';
import { Page } from './movement/page';
import { registerContactFormHandler } from './contact';
import { getById } from './util/dom';

let engine: Engine;

async function init() {
  await document.fonts.ready;
  document.body.style.visibility = 'visible';

  registerContactFormHandler();
  registerLinkClickHandler();
  engine = new Engine();
  // Wait a frame before navigating to prevent jank.
  requestAnimationFrame(() => navigate());
}

function registerLinkClickHandler() {
  getById('gravity-link').addEventListener('click', () => {
    const el = getById('gravity-link');
    if (el.classList.contains('active')) {
      el.classList.remove('active');
      engine.toggleGravity(false);
    } else {
      el.classList.add('active');
      engine.toggleGravity(true);
    }
  });

  document.addEventListener('click', (e: MouseEvent) => {
    let link = e.target as HTMLElement|null|undefined;
    if (link?.tagName !== 'A') {
      link = link?.closest('a');
    }
    const href = link?.getAttribute('href');
    if (!href || !href.startsWith('/')) return;

    e.preventDefault();
    history.pushState(undefined, '', href);
    navigate(href);
  });

  addEventListener('popstate', () => {
    navigate();
  });
}

function navigate(url?: string) {
  if (!url) {
    url = location.pathname;
  }
  const page = Object.values(Page).find(p => p === url.substring(1)) ?? null;
  engine.navigate(page);

  const currentLink = document.querySelector('#header .current');
  currentLink?.classList.remove('current');
  for (const link of Array.from(document.querySelectorAll('#header a'))) {
    if (link.getAttribute('href') === url) {
      link.parentElement!.classList.add('current');
    }
  }
}


if (document.readyState === "complete") {
  init();
} else {
  window.addEventListener("load", init);
}


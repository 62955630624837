import { createRectangle, Location, Rectangle } from "../util/types";
import { Page, PAGE_CONFIGS } from "./page";
import { getBallRadius, getEnvironmentState } from './environment';
import { Circle, cornersToCircles, Line } from "./collidable";
import { clamp, valueAlongScale } from "../util/math";
import { Bullet } from "./bullet";
import { easeInOutQuad } from "../util/animation";
import { isTabFocused } from "src/util/dom";

enum Direction {
  OPEN,
  CLOSE,
}

// When closing, we want to turn the page back into a ball as soon
// as possible.
const CLOSING_DURATION_PERCENT_CUTOFF = 0.94;

/** The distance from a perfect rectangle to the rounded corners that we have.  */
function getCornerDistance(): number {
  return getEnvironmentState().pageBorderRadius * Math.cos(Math.PI * 0.25);
}

export class PageTransformer {
  readonly moveable = false;

  readonly config = PAGE_CONFIGS[this.page];
  readonly ballEl: HTMLElement;
  readonly wrapperEl: HTMLElement;
  readonly contentEl: HTMLElement;
  readonly innerEl: HTMLElement;

  private timePassed: DOMHighResTimeStamp = Number.POSITIVE_INFINITY;
  duration: DOMHighResTimeStamp = 0;
  private direction = Direction.CLOSE;
  private expansionPercent = 0;

  private contentWidth: number = 0;
  private contentHeight: number = 0;
  private wrapperDimension: number = 0;

  private isTabbingEnabled = true;
  // Used for `get circle()` so that we don't have to construct a new object every time it's called.
  private circle_: Circle = {
    type: 'circle',
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    radius: 0,
    dradius: 0,
  };

  lines: Line[] = [];
  rectangle: Rectangle;
  // This represents the border-radius's of the page.
  // In order of top-left, top-right, bottom-left, bottom-right.
  cornerCircles: Circle[];

  dradius: number;

  constructor(readonly page: Page, readonly bullet: Bullet) {
    const ballEl = document.querySelector(`.ball[data-page="${page}"]`);
    if (!ballEl) throw 'Could not find ballEl';
    this.ballEl = ballEl as HTMLElement;
    this.wrapperEl = this.ballEl.querySelector('.wrapper') as HTMLElement;
    this.contentEl = this.ballEl.querySelector('.content') as HTMLElement;
    this.innerEl = this.ballEl.querySelector('.inner') as HTMLElement;

    this.applyMeasurements();
    this.setExpansionPercentage(0);

    this.disableTabbing();
  }

  handleResize() {
    this.applyMeasurements();
  }

  private applyMeasurements() {
    this.contentWidth = this.contentEl.offsetWidth;
    this.setCssProperty('--content-width-px', this.contentWidth + 'px');
    this.contentHeight = this.contentEl.offsetHeight;
    this.setCssProperty('--content-height-px', this.contentHeight + 'px');

    const contentDiagonal = Math.sqrt(this.contentWidth * this.contentWidth + this.contentHeight * this.contentHeight);
    this.wrapperDimension = contentDiagonal - getCornerDistance();
    this.setCssProperty('--wrapper-dimension', this.wrapperDimension);
    this.setCssProperty('--wrapper-dimension-px', this.wrapperDimension + 'px');

    // TODO - this should get updated after a transition is complete.
    this.duration = this.calculateDuration();

    this.rectangle = createRectangle({ 
      width: this.contentWidth,
      height: this.contentHeight,
      center: this.center,
      borderRadius: getEnvironmentState().pageBorderRadius,
    });
    this.cornerCircles = cornersToCircles(this.rectangle);

    this.circle_.x = this.center.x;
    this.circle_.y = this.center.y;
  }

  initFrame(dt: DOMHighResTimeStamp) {
    this.dradius = this.calculateDRadius(dt);
  }

  move(dt: DOMHighResTimeStamp, frameTimeRemaining: DOMHighResTimeStamp) {
    this.expansionPercent = this.calculateExpansionPercentage(dt);
    this.timePassed += dt;
    if (this.isComplete) {
      this.dradius = 0;
    } else {
      this.dradius = this.calculateDRadius(frameTimeRemaining);
    }
    this.updateLines();
  }

  get center(): Location {
    return getEnvironmentState().screenCenter;
  }

  private updateLines() {
    const radius = this.radius;
    const diameter = radius * 2;

    const lines: Line[] = [];

    const borderRadius = getEnvironmentState().pageBorderRadius;
    const maxWidth = this.contentWidth - borderRadius * 2;
    const maxHeight = this.contentHeight - borderRadius * 2;

    if (diameter > maxWidth) {
      const width = this.contentWidth;
      const halfWidth = width * 0.5;
      const height = Math.min(maxHeight, 2 * Math.sqrt(radius * radius - halfWidth * halfWidth));
      const halfHeight = height * 0.5;
      const center = this.center;
      const top = center.y - halfHeight;
      const dtop = -this.dradius;
      const bottom = center.y + halfHeight;
      const dbottom = this.dradius;
      lines.push(
        // left line
        {
          type: 'vertical-line',
          x: this.rectangle.left,
          top, dtop, bottom, dbottom,
        },
        // right line
        {
          type: 'vertical-line',
          x: this.rectangle.right,
          top, dtop, bottom, dbottom,
        }
      );
    }
    if (diameter > maxHeight) {
      const height = this.contentHeight;
      const halfHeight = height * 0.5;
      const width = Math.min(maxWidth, 2 * Math.sqrt(radius * radius - halfHeight * halfHeight));
      const halfWidth = width * 0.5;
      const center = this.center;
      const left = center.x - halfWidth;
      const dleft = -this.dradius;
      const right = center.x + halfWidth;
      const dright = this.dradius;
      lines.push(
        // top line
        {
          type: 'horizontal-line',
          y: this.rectangle.top,
          left, dleft, right, dright,
        },
        // bottom line
        {
          type: 'horizontal-line',
          y: this.rectangle.bottom,
          left, dleft, right, dright,
        }
      );
    }
    this.lines = lines;
  }

  get circle(): Circle {
    const {circle_} = this;
    // circle_.x and circle_.y are updated in applyMeasurements().
    circle_.radius = this.radius;
    circle_.dradius = this.dradius;
    return circle_;
  }

  private calculateDRadius(timeWindow: DOMHighResTimeStamp) {
    if (this.isComplete || this.direction === Direction.CLOSE) return 0;
    const {duration, timePassed} = this;
    
    // The middle of the transformation is the fastest so just always chose that.

    const dt = 0.000001;

    const endTime = duration / 2 - timePassed;
    
    // I'm too lazy to figure out how to do this with calculus.
    const startRadius = this.calculateNextRadius(endTime - dt);
    const endRadius = this.calculateNextRadius(endTime);
    const actual = ((endRadius - startRadius) / dt);

    // A really low value isn't helpful because it will always round to 0.
    if (actual > 0 && actual < 0.01) return 0.005;
    return actual;
  }
  
  get radius(): number {
    return this.calculateRadiusAt(this.expansionPercent);
  }

  private calculateNextRadius(dt: DOMHighResTimeStamp): number {
    return this.calculateRadiusAt(this.calculateExpansionPercentage(dt));
  }

  calculateRadiusAt(expansionPercent: number): number {
    const start = getBallRadius(this.config).big;
    const end = this.wrapperDimension * 0.5;
    return (end - start) * expansionPercent + start;
  }

  startOpen() {
    this.startTransition(Direction.OPEN);
    this.bullet.startTransform(this.duration);
    this.ballEl.classList.add('current');
  }

  startClose() {
    this.startTransition(Direction.CLOSE);
    this.bullet.reverseTransform(this.duration);
    this.ballEl.classList.remove('current');
    this.disableTabbing();
  }

  private startTransition(direction: Direction) {
    if (this.isComplete) {
      this.timePassed = 0;
    } else {
      if (this.direction === direction) {
        return;
      }
      this.timePassed = this.duration - this.timePassed;
    }
    this.direction = direction;
  }

  render() {
    this.setExpansionPercentage(this.expansionPercent);
  }

  get isExpanding(): boolean {
    return this.direction === Direction.OPEN && !this.isComplete;
  }

  get isFullyOpen(): boolean {
    return this.direction === Direction.OPEN && this.isComplete;
  }

  get isComplete(): boolean {
    if (this.direction === Direction.OPEN) {
      return this.timePassed >= this.duration;
    } else {
      return this.timePassed >= this.duration * CLOSING_DURATION_PERCENT_CUTOFF;
    }
  }

  get isActive(): boolean {
    return !this.isComplete || this.direction === Direction.OPEN;
  }

  private setCssProperty(name: string, value: string|number) {
    this.ballEl.style.setProperty(name, value.toString());
  }

  private calculateExpansionPercentage(dt: DOMHighResTimeStamp): number {
    const timePassed = this.timePassed + dt;
    let percent = this.duration ? timePassed / this.duration : 1;
    percent = clamp(percent, 0, 1);
    if (this.direction === Direction.CLOSE) {
      percent = 1 - percent;
    }
    percent = easeInOutQuad(percent);
    if (this.direction === Direction.CLOSE && timePassed >= this.duration * CLOSING_DURATION_PERCENT_CUTOFF) {
      percent = 0;
    }
    return percent;
  }

  setExpansionPercentage(percent: number) {
    this.setCssProperty('--transition-percent', percent);
  }

  private calculateDuration(): number {
    const ballDiameter = getBallRadius(this.config).big * 2;
    const percent = clamp((this.wrapperDimension - ballDiameter) / 800, 0, 1);
    return valueAlongScale(330, 670, percent);
  }

  private disableTabbing() {
    if (!this.isTabbingEnabled) return;
    this.isTabbingEnabled = false;
    for (const el of this.getTabbableElements()) {
      const current = el.getAttribute('tabindex');
      if (current) {
        el.dataset.tabIndex = current;
      }
      el.setAttribute('tabindex', '-1');
    }
  }

  enableTabbing() {
    if (this.isTabbingEnabled) return;
    this.isTabbingEnabled = true;

    // Move the current tab into the page
    if (isTabFocused()) {
      this.innerEl.focus();
    }

    for (const el of this.getTabbableElements()) {
      if (el.dataset.tabIndex !== undefined) {
        el.setAttribute('tabindex', el.dataset.tabIndex);
      } else {
        el.removeAttribute('tabindex');
      }
    }
  }

  serialize(): Object {
    return {
      timePassed: this.timePassed,
      duration: this.duration,
      direction: this.expansionPercent,
      radius: this.radius,
      dradius: this.dradius,
      lines: this.lines.slice(),
    }
  }

  private getTabbableElements(): HTMLElement[] {
    // This isn't comprehensive, just what we need for this site.
    return Array.from(this.contentEl.querySelectorAll('input, textarea, a[href], button, [tabindex="0"]')) as HTMLElement[];
  }
}